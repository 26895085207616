



import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

@Component
export default class Descarga extends Vue {
  private mounted() {
    let disp = [
      "alp-",
      "amn-",
      "ana-",
      "ane-",
      "ang-",
      "aqm-",
      "ars-",
      "art-",
      "atu-",
      "bac-",
      "bla-",
      "brq-",
      "cag-",
      "cam-",
      "can-",
      "caz-",
      "cdl-",
      "cdy-",
      "clt-",
      "cro-",
      "cun-",
      "dig-",
      "dra-",
      "dua-",
      "dub-",
      "dvc-",
      "ele-",
      "els-",
      "eml-",
      "eva-",
      "evr-",
      "fig-",
      "fla-",
      "frl-",
      "glk-",
      "hma-",
      "hw-",
      "hwi-",
      "ine-",
      "jat-",
      "jef-",
      "jer-",
      "jkm-",
      "jny-",
      "jsc-",
      "ldn-",
      "lio-",
      "lon-",
      "lua-",
      "lya-",
      "lyo-",
      "mar-",
      "med-",
      "mha-",
      "mla-",
      "mrd-",
      "mya-",
      "nce-",
      "neo-",
      "noh-",
      "nop-",
      "oce-",
      "par-",
      "pic-",
      "pot-",
      "ppa-",
      "pra-",
      "rne-",
      "sea-",
      "sla-",
      "sne-",
      "spn-",
      "stk-",
      "tah-",
      "tas-",
      "tet-",
      "trt-",
      "vce-",
      "vie-",
      "vky-",
      "vns-",
      "vog-",
      "vtr-",
      "was-",
      "wkg-",
      "wlz-",
      "yal"
    ];
    let huawei = false;
    for (let i = 0; i < disp.length; i++) {
      if (navigator.userAgent.toLowerCase().indexOf(disp[i]) > -1) {
        huawei = true;
      }
    }
    if (huawei) {
      window.location.href = "https://appgallery.huawei.com/app/C105015639";
    } else if (
      navigator.userAgent.toLowerCase().indexOf("harmony") > -1 ||
      navigator.userAgent.toLowerCase().indexOf("huawei") > -1
    ) {
      window.location.href = "https://appgallery.huawei.com/app/C105015639";
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=cl.meetcard.tarjetavecino.com.puntaarenas.puntaarenas_app";
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href =
        "https://apps.apple.com/us/app/apparenas/id1588900003";
    }
    this.$router.push("Home");
  }
}
